import JSBI from 'jsbi'

export const FACTORY_ADDRESS = '0x0F89D86f74501A82765396F1B21d7B02F7Ba2aAd'

export const INIT_CODE_HASH = '0x18d0600727c05990a69c1751802f6d49abc984a14d65c87cf89b69b402487433'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
